import React, {useEffect, useState} from 'react';
import {Nav} from "../commons/nav";
import OrderAssignForm from "./form/order-assign";
import Modal from "react-modal";
import {default as _, startCase} from 'lodash';
import {
  formatAMPM,
  diffMinutes,
  formatScanTypes,
  scanTypeLabel,
  fullName,
  isAge,
  getRiderState,
  makeCopiedOrder, isCustomerFrustrated, hasPermission,
} from '../../utils/helper';
import ReactMinimalPieChart from 'react-minimal-pie-chart';
import {
  ORDER_DETAIL_RIDER_INTERVAL,
  STATES_TO_CANCEL,
  STATES_TO_ASSIGN,
  STATES_TO_REASSIGN,
  ORDER_STATE,
  STATES_TO_RESCHEDULE,
  STATES_TO_SHOW_TIMER,
  STATES_TO_DELIVER,
  FULFILLMENT_METHOD_TYPES,
  STATES_TO_OFFLINE_IN_PROGRESS,
  STATES_TO_VERIFY,
  MAX_FOLLOW_UP_COUNT,
  PERMISSION,
  VERTICALS,
  IDLE_RIDER_STATES,
  BUSY_RIDER_STATES,
} from "../../utils/constants";
import {RiderTasksList} from '../commons/rider-tasks-list';
import RescheduleOrder from './RescheduleOrder';
import OrderActivity from './order-activity';
import OrderCancel from './order-cancel';
import Rating from './rating';
import OrderReviewPopup from './OrderReviewPopup';
import PinActivity from "./PinActivity";
import happyIcon from '../../assets/images/happy-icon.svg'
import angryIcon from '../../assets/images/angry-icon.svg'
import FollowUp from "./follow-up";
import AdditionalNotes from "./AdditionalNotes";
import Autocomplete from "react-autocomplete";
import {Loader} from "../commons/loader";
import {toast} from "react-toastify";
import RiderToPatientFeedbackPopup from "./RiderToPatientFeedbackPopup";
import DeleteCustomerAddress from "./DeleteCustomerAddress";
import {ROUTES} from "../../routes/constants";
import idleRiderMarker from "../../assets/images/idle-rider-marker.svg";
import busyRiderMarker from "../../assets/images/busy-rider-marker.svg";
import wildcardRiderMarker from "../../assets/images/wildcard-rider-marker.svg";
import covidRiderMarker from "../../assets/images/covid-rider-marker.svg";
import {Popup, Circle, Marker} from "react-leaflet";
import {CollectedIcon, CustomerIcon, ReachedIcon, RiderIcon} from "../../assets/images/Icons";
import Map from "../commons/Map";

const BloodOrdersDetail = props => {
  const {
    match: {params: {id}},
    history,
    getOrderDetail,
    orderDetail,
    riderDetail,
    regions,
    reAssignOrder,
    assignOrder,
    getCustomerDetail,
    cancelOrder,
    resetCustomerState,
    bloodOrderParams,
    getRiderLocation,
    riderTasks,
    getRiderTasks,
    getAssignableRider,
    assignableRider,
    resetAssignableRiderState,
    assignableRiderLoading,
    ordersLoading,
    rescheduleOrder,
    orderActivities,
    getOrderActivities,
    resetOrderActivitiesState,
    resetRiderTasksState,
    getOrderTimeSlots,
    resetOrderTimeSlotsState,
    orderTimeSlots,
    copyOrder,
    orderNeedsReview,
    offlineInProgress,
    deliverOrder,
    verifyExternalOrder,
    followUpOrder,
    updateAdditionalNotes,
    scanTypes,
    resetScanTypesState,
    getOrderScanTypes,
    scanTypesLoading,
    updateScanTypes,
    getCancellationStrings,
    cancellationStrings,
    getReassignmentStrings,
    reassignmentStrings,
    deleteCustomerAddress,
    getRescheduleStrings,
    rescheduleStrings,
  } = props;
  const [customerDetail, setCustomerDetail] = useState(null);
  const [rider, setRiderDetail] = useState({});
  const [riderPosition, setRiderPosition] = useState({});
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [mode, setMode] = React.useState('');
  const [isOpenRiderTasksModal, setIsOpenRiderTasksModal] = useState(false);
  const [isOpenOrderActivityModal, setIsOpenOrderActivityModal] = useState(false);
  const [isOpenCancelOrderModal, setIsOpenCancelOrderModal] = useState(false);
  const [isOpenRescheduleOrderModal, setIsOpenRescheduleOrderModal] = useState(false);
  const [isOpenOrderReviewModal, setIsOpenOrderReviewModal] = useState(false);
  const [isOpenRiderToPatientFeedbackModal, setIsOpenRiderToPatientFeedbackModal] = useState(false);
  const [isPinActivityModalOpen, setPinActivityModal] = useState(false)
  const [isFollowUpModalOpen, setIsFollowUpModal] = useState(false)
  const [isAdditionalNotesModalOpen, setIsAdditionalNotesModalOpen] = useState(false);
  const [isOpenDeleteCustomerAddressModal, setIsOpenDeleteCustomerAddressModal] = React.useState(false);
  const [isEditTestsPanelOpen, setIsEditTestsPanelOpen] = useState(false);
  const [test, setTest] = useState('');
  const [tests, setTests] = useState([]);
  const [discount, setDiscount] = useState(0);

  const testsTotal = _.sumBy(tests, t => parseInt(t.value));
  const value = testsTotal - ((discount * testsTotal) / 100);

  const prescriptionFiles = orderDetail.prescriptionFiles ? [...orderDetail.prescriptionFiles] : [];

  if (orderDetail.prescriptionImage) {
    prescriptionFiles.push(orderDetail.prescriptionImage);
  }

  const prescriptionStyling = `prescription-image-container ${prescriptionFiles.length === 0 ? 'overlay-order-popup' : 'overlay-order-popup-with-image'}`;

  useEffect(() => {
    getOrderDetail({id, vertical: VERTICALS.blood});
    if (!cancellationStrings.length) getCancellationStrings();
    if (!reassignmentStrings.length) getReassignmentStrings();
    if (!rescheduleStrings.length) getRescheduleStrings();
  }, []);

  useEffect(() => {
    setCustomerDetail(orderDetail.customer);
    if (orderDetail.rider) {
      setRiderDetail(orderDetail.rider);
      setRiderPosition({lat: orderDetail.rider.lastKnownLatitude, lng: orderDetail.rider.lastKnownLongitude});
    } else {
      setRiderDetail({});
      setRiderPosition({});
    }
  }, [orderDetail]);

  useEffect(() => {
    if (['collected', 'assigned'].includes(orderDetail.state)) {
      const interval = setInterval(() => {
        if (rider.id) {
          getRiderLocation(rider.id);
          if (riderDetail.profile) {
            setRiderPosition({lat: riderDetail.profile.lastKnownLatitude, lng: riderDetail.profile.lastKnownLongitude});
          }
        }
      }, ORDER_DETAIL_RIDER_INTERVAL);
      return () => clearInterval(interval);
    }
  }, [rider, riderDetail]);

  useEffect(() => {
    getOrderActivities({order: id});
  }, [])

  const handleNeedsReview = () => {
    if (orderDetail.needsReview) {
      orderNeedsReview({
        id: orderDetail.id,
        vertical: VERTICALS.blood,
        data: {
          needsReview: false,
        }
      })
    } else {
      orderNeedsReview({
        id: orderDetail.id,
        vertical: VERTICALS.blood,
        data: {
          needsReview: true,
        }
      })
    }
  };

  const openModal = (e, mode) => {
    e.preventDefault();
    setIsOpen(true);
    setMode(mode);
  };

  const openEditTestsPanel = () => {
    if (orderDetail.scanTypes) {
      let separatedScanTypes = []
      let scanTypeIndex = 0
      for (const test of orderDetail.scanTypes) {
        for (let i = 0; i < test.count; i++) {
          separatedScanTypes.push({
            id: test.scanType.id,
            name: test.scanType.name,
            code: test.scanType.code,
            value: test.scanType.value,
            index: scanTypeIndex++
          })
        }
      }
      setTests(separatedScanTypes)
    }
    setIsEditTestsPanelOpen(true);
  }

  const orderCancel = (orderId, data) => {
    cancelOrder({id: orderId, vertical: VERTICALS.blood, data, filter: bloodOrderParams});
    setIsOpenCancelOrderModal(false);
    history.goBack();
  };

  const orderFollowUp = (orderId, data) => {
    followUpOrder({orderId, reason: data.reason})
    getOrderDetail({id: orderId, vertical: VERTICALS.blood});
    setIsFollowUpModal(false)
  }

  const closeModal = () => {
    setIsOpen(false);
    resetCustomerState();
    resetAssignableRiderState();
  }

  const closeRiderTasksModal = () => {
    setIsOpenRiderTasksModal(false);
  };

  const closeOrderActivityModal = () => {
    setIsOpenOrderActivityModal(false)
  };

  const closePinActivityModal = () => {
    setPinActivityModal(false)
  };

  const closeRescheduleOrderModal = () => {
    setIsOpenRescheduleOrderModal(false)
  };

  const closeOrderReviewModal = () => {
    setIsOpenOrderReviewModal(false)
  };

  const closeRiderToPatientFeedbackModal = () => {
    setIsOpenRiderToPatientFeedbackModal(false)
  };

  const closeCancelOrderModal = () => {
    setIsOpenCancelOrderModal(false);
  };

  const closeFollowUpOrderModal = () => {
    setIsFollowUpModal(false)
  }

  const closeAdditionalNotesModal = () => {
    setIsAdditionalNotesModalOpen(false)
  }

  const closeEditTestsPanel = () => {
    setIsEditTestsPanelOpen(false)
    setTests([])
  }

  const getPickupGraphValue = (pickup) => {
    return diffMinutes(new Date(), pickup);
  }
  const showGender = (gender) => {
    switch (gender) {
      case 1:
        return 'Male';
      case 2:
        return 'Female';
      case 3:
        return 'Other';
      default:
        return 'Other';

    }
  }

  const getOrderStateIcon = (state) => {
    let image;
    switch (state) {
      case ORDER_STATE.SCHEDULED:
        image = 'icon1.png';
        break;
      case ORDER_STATE.NEEDS_ASSIGNMENT:
        image = 'icon2.png';
        break;
      case ORDER_STATE.ASSIGNED:
        image = 'icon3.png';
        break;
      case ORDER_STATE.COLLECTED:
        image = 'icon4.png';
        break;
      case ORDER_STATE.DELIVERED:
        image = 'icon5.png';
        break;
      case ORDER_STATE.PROBLEM:
        image = 'icon6.png';
        break;
      case ORDER_STATE.CANCELLED:
        image = 'icon7.png';
        break;
      default:
        image = 'icon2.png';
    }

    return require(`../../assets/images/${image}`)
  };

  const getRiderIcon = rider => {
    let riderIcon;
    if (IDLE_RIDER_STATES.includes(+rider.state)) {
      riderIcon = idleRiderMarker;
    }
    if (BUSY_RIDER_STATES.includes(+rider.state)) {
      riderIcon = busyRiderMarker;
    }
    if (rider.isWildcard) {
      riderIcon = wildcardRiderMarker;
    }
    if (rider.isCovid) {
      riderIcon = covidRiderMarker;
    }

    return riderIcon;
  };

  const orderAdditionalNotes = (additionalNotes) => {
    updateAdditionalNotes({
      id: orderDetail.id,
      vertical: VERTICALS.blood,
      data: {
        additionalNotes,
      }
    });
    setIsAdditionalNotesModalOpen(false)
  }

  const searchOrderScanTypes = (test) => {
    if (test.length === 0) {
      resetScanTypesState();
      return;
    }
    getOrderScanTypes({search: test, vertical: VERTICALS.BLOOD});
  };

  const orderScanTypes = () => {
    if (!tests.length) {
      toast.error('Select test type');
      return;
    }
    updateScanTypes({orderId: orderDetail.id, value: value, scanType: tests.map(test => test.code)})
    closeEditTestsPanel();
  };

  const closeDeleteCustomerAddressModal = () => {
    setIsOpenDeleteCustomerAddressModal(false)
  }

  const handleCustomerAddressDeletion = customerAddressId => {
    deleteCustomerAddress({customerAddressId});
    getOrderDetail({id, vertical: VERTICALS.blood});
    closeDeleteCustomerAddressModal();
  }

  const {
    state,
    customerAddress,
    number,
    pickup,
    allowManualAssignment,
    review: orderReview = null,
    riderToPatientFeedback,
    additionalNotes,
    reachedThresholdDistance
  } = orderDetail;
  const customerLocation = [+customerAddress?.latitude, +customerAddress?.longitude];
  const totalFollowUps = orderDetail.totalFollowUps || 0

  return <div id="wrapper" className={`inner-page ${modalIsOpen ? 'order-assigin-popup-active' : ''}`}>
    <Nav/>
    {orderDetail && orderDetail.id && <div className="order-detail-page">
      <div className="order-detail-right">
        <ul className="btn-list">
          {
            customerAddress.isValid && orderDetail.fulfillmentMethod === FULFILLMENT_METHOD_TYPES.ONLINE &&
            <li>
              <button
                className="red"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpenDeleteCustomerAddressModal(true);
                }}>
                Delete Address
              </button>
            </li>
          }
          {hasPermission(PERMISSION.CAN_COPY_ORDER) &&
          <li>
            <button
              className="green"
              onClick={(e) => {
                e.preventDefault();
                copyOrder(makeCopiedOrder(orderDetail));
                history.push(`${ROUTES.BLOOD_ORDERS}/create`)
              }}>
              Copy Order
            </button>
          </li>}
          {hasPermission(PERMISSION.CAN_RESCHEDULE_ORDER) && STATES_TO_RESCHEDULE.indexOf(state) > -1 && orderDetail.fulfillmentMethod === FULFILLMENT_METHOD_TYPES.ONLINE &&
          <li>
            <button
              className="blue next"
              onClick={(e) => {
                setIsOpenRescheduleOrderModal(true)
              }}>
              Reschedule
            </button>
          </li>}
          {hasPermission(PERMISSION.CAN_ASSIGN_ORDER) && STATES_TO_ASSIGN.indexOf(state) > -1 && allowManualAssignment && orderDetail.fulfillmentMethod === FULFILLMENT_METHOD_TYPES.ONLINE &&
          <li>
            <button className="yellow" onClick={(e) => openModal(e, 'assign')}>Assign</button>
          </li>}
          {hasPermission(PERMISSION.CAN_ASSIGN_ORDER) && STATES_TO_REASSIGN.includes(state) && allowManualAssignment && orderDetail.fulfillmentMethod === FULFILLMENT_METHOD_TYPES.ONLINE &&
          <li>
            <button className="blue refresh" onClick={(e) => openModal(e, 'reassign')}>Re-assign</button>
          </li>}
          {STATES_TO_OFFLINE_IN_PROGRESS.indexOf(state) > -1 && orderDetail.fulfillmentMethod === FULFILLMENT_METHOD_TYPES.OFFLINE &&
          <li>
            <button className="blue"
                    onClick={(e) => offlineInProgress({id: orderDetail.id, vertical: VERTICALS.blood})}>Move in Progress
            </button>
          </li>}
          {STATES_TO_DELIVER.indexOf(state) > -1 &&
          <li>
            <button className="green"
                    onClick={(e) => deliverOrder({id: orderDetail.id, vertical: VERTICALS.blood})}>Deliver Order
            </button>
          </li>}
          {STATES_TO_VERIFY.indexOf(state) > -1 &&
          <li>
            <button className="green"
                    onClick={(e) => verifyExternalOrder({id: orderDetail.id, vertical: VERTICALS.blood})}>Verify Order
            </button>
          </li>}
          {STATES_TO_CANCEL.indexOf(state) > -1 &&
          <li>
            <button className="red" onClick={(e) => setIsOpenCancelOrderModal(true)}>Cancel Order</button>
          </li>}
          {new Date() > new Date(pickup) &&
          <li>
            <button
              className="blue"
              onClick={(e) => {
                setIsFollowUpModal(true)
              }
              }>
              Mark Follow Up
            </button>
          </li>
          }

        </ul>
        <div className="detail-map">
          <span className="map-info">{customerAddress.address}</span>
          <span className={`verified-info ${customerAddress.verified ? 'verified-address' : 'unverified-address'}`}> {customerAddress.verified ? "Verified" : "Unverified" } </span>
          <Map
            initialCenter={customerLocation}
            initialZoom={15}
            styles={{height: "100%", zIndex: 0}}
          >
            <Circle
              pathOptions={{ stroke: false, fillOpacity: 0.2, fillColor: '#4F5CD3' }}
              center={customerLocation}
              radius={reachedThresholdDistance} />
            <Marker
              icon={CustomerIcon}
              position={customerLocation}>
              <Popup>
                {customerAddress.address}
              </Popup>
            </Marker>
            {
              orderDetail.rider &&
              <Marker
                icon={RiderIcon}
                position={[+rider.lastKnownLatitude, +rider.lastKnownLongitude]}>
                <Popup>
                  Rider {rider.firstName + " " + rider.lastName}
                </Popup>
              </Marker>
            }
            {
              orderDetail.riderReachedLatitude && orderDetail.riderReachedLongitude &&
              <Marker
                icon={ReachedIcon}
                position={[+orderDetail.riderReachedLatitude, +orderDetail.riderReachedLongitude]}>
                <Popup>
                  Rider {rider.firstName + " " + rider.lastName} marked reached
                </Popup>
              </Marker>
            }
            {
              orderDetail.riderCollectionLatitude && orderDetail.riderCollectionLongitude &&
              <Marker
                icon={CollectedIcon}
                position={[+orderDetail.riderCollectionLatitude, +orderDetail.riderCollectionLongitude]}>
                <Popup>
                  Rider {rider.firstName + " " + rider.lastName} marked collected
                </Popup>
              </Marker>
            }
          </Map>
        </div>
        {rider.id && <div className="assign-to-block">
          <div className="left-info">
            {state === 'delivered' ? <h3>Delivered by</h3> : <h3>Assigned to</h3>}
            <i className="icon-biker"/>
          </div>
          <div className="right-info">
            <h2><span
              className="idle">{getRiderState(rider.state)}</span> {rider.firstName} {rider.lastName}</h2>
            <span className="tel">
                        <i className="icon-cell"/>
              {rider.phone} ({rider.isOnDuty ? 'On Duty' : 'Off Duty'})
                        </span>
            <a
              href=''
              className="link"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/riders/detail/${rider.id}`)
              }
              }
            >View Profile</a>
            <br/>
            <a
              href=''
              className="link"
              onClick={(e) => {
                e.preventDefault();
                setIsOpenRiderTasksModal(true)
              }}>View Tasks</a>
            <br/>
            {riderToPatientFeedback && riderToPatientFeedback.isComplete &&
            <a href='' className="link" onClick={e => {
              e.preventDefault();
              setIsOpenRiderToPatientFeedbackModal(true)
            }}>Feedback for Patient</a>
            }
          </div>
        </div>}

      </div>
      <div className="order-detail-left">
        <h1>{number}</h1>
        <ul className="breadcrumb-page">
          <li><a href=''
                 onClick={(e) => {
                   e.preventDefault();
                   history.goBack();
                 }}>Orders</a></li>
          <li>{number}</li>
        </ul>
        <div className="detail-cols">
          <div className="detail-col">
            <div className="assign-order-block">
              <div className="holder">
                <img src={getOrderStateIcon(orderDetail.state)} alt=""/>
                <i
                  title={orderDetail.needsReview ? 'Needs Review' : 'Reviewed'}
                  className={`icon-star-round ${orderDetail.needsReview ? '' : 'yellow'}`}
                  onClick={handleNeedsReview}></i>
                <div className="info">
                  <span className="title">{startCase(orderDetail.state)}</span>
                  <a href="#" className="link"
                     onClick={(e) => {
                       e.preventDefault();
                       setIsOpenOrderActivityModal(true);
                     }}
                  >View Activity</a>
                  <div className="link"
                       onClick={(e) => {
                         e.preventDefault();
                         setPinActivityModal(true);
                       }}
                  >View Pin Activity
                  </div>
                </div>
              </div>
              <div className="white-block">
                {
                  orderReview && orderReview.isComplete ?
                    <div className="ratting-row">
                      <Rating rating={orderReview.overallStarRating}/>
                      <span className="point">{orderReview.overallStarRating | '0'}</span>
                      <a href=" "
                         className="view"
                         onClick={(e) => {
                           e.preventDefault();
                           setIsOpenOrderReviewModal(true)
                         }}
                      >View Details</a>
                    </div>
                    :
                    <div className="no-ratting">
                      <span className="title">Feedback Pending...</span>
                    </div>
                }
              </div>
            </div>
            <div className="pick-block">
              {
                STATES_TO_SHOW_TIMER.indexOf(state) === -1 && <div className="time-graph">
                  <ReactMinimalPieChart
                    animate={true}
                    animationDuration={500}
                    animationEasing="ease-out"
                    cx={50}
                    cy={50}
                    data={[
                      {
                        color: '#a83e32',
                        value: getPickupGraphValue(orderDetail.pickup)
                      }
                    ]}
                    label
                    labelPosition={0}
                    labelStyle={{
                      fontFamily: 'sans-serif',
                      fontSize: '25px'
                    }}
                    lengthAngle={360}
                    lineWidth={30}
                    totalValue={60}

                  />
                </div>
              }
              <div
                className="info">
                <span className="title">Pick up time</span>
                <span className="time">{`${formatAMPM(new Date(pickup))}`}</span>
                <span className="day">{`${new Date(pickup).toLocaleDateString()}`}</span>
              </div>
            </div>
            <div className="customer-block">
              <h2>Customer Profile</h2>
              <h3>{`${fullName(orderDetail.patient)}`}</h3>
              <div className="gender-info">
                <span className="gender">{showGender(orderDetail.patient.gender)}</span>
                <span
                  className="age">{`${orderDetail.patient?.ageString}`}</span>
              </div>
              <span className="tel">
                          <i className="icon-cell"/> {orderDetail.patient.phone}
                        </span>
              <span className="tel">
                          <i className="icon-cell"/> {orderDetail.patient.secondaryPhone}
                        </span>
              <span className="mail">
                        <i className="icon-email"/>
                {orderDetail.patient.email}
                        </span>
              <div className="last-order">
                <span className="title">Last Order</span>
                {orderDetail.customer.lastOrder && <span
                  className="date">{`${new Date(orderDetail.customer.lastOrder.created).toLocaleDateString()} ${formatAMPM(new Date(orderDetail.customer.lastOrder.created))}`}
                                </span>
                }
              </div>
            </div>
          </div>
          <div className="detail-col">
            <div className="order-detail-block">
              <div className="head">
                <h2>Order Details</h2>
                {orderDetail && !orderDetail.collectedAt && <a className="link action icon-edit" onClick={(e) => {
                  openEditTestsPanel()
                }}></a>}
              </div>
              <ul className="test-list-detail">
                {
                  formatScanTypes(orderDetail.scanTypes).map(scanType => (
                    <li key={scanType.id}>{scanTypeLabel(scanType)}</li>
                  ))
                }
              </ul>

              <div className="footer">
                <span className="title">ORDER VALUE</span>
                <span className="total">PKR {orderDetail.givenValue}</span>
              </div>
            </div>
            <div className="com-block">
              <div className="head">
                <h2>Additional Notes</h2>
                {orderDetail && !orderDetail.collectedAt && <a className="link action icon-edit" onClick={(e) => {
                  setIsAdditionalNotesModalOpen(true)
                }}></a>}
              </div>
              <ul className="com-list">
                <li>
                  <p>{additionalNotes ? additionalNotes : ' '}</p>
                </li>
              </ul>
            </div>
            <div className="com-block">
              <div className="head">
                <h2>Customer Frustration Meter</h2>
              </div>
              <div className='frustration-meter-container'>
                <div className='frustration-meter'>
                  <div className='icon-holder'>
                    <img src={happyIcon}/>
                  </div>
                  {
                    [...Array(MAX_FOLLOW_UP_COUNT)].map((x, i) => <div
                      className={`frustration-meter-bar ${totalFollowUps && i < totalFollowUps && (isCustomerFrustrated(totalFollowUps) ? 'angry' : 'happy')}`}>
                    </div>)
                  }
                  <div className='icon-holder'>
                    <img src={angryIcon}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={isEditTestsPanelOpen && "order-detail-popup-active"}>
        {isEditTestsPanelOpen && prescriptionFiles.length > 0 && (
          <div className={prescriptionStyling}>
            <div className="prescription-container">
              {prescriptionFiles.map((file, index) => {
                const isPDF = file.toLowerCase().endsWith('.pdf');

                if (isPDF) {
                  return (
                    <object
                      key={index}
                      data={file}
                      type="application/pdf"
                      width="100%"
                      height="500px"
                      className="prescription-image"
                    >
                      <p>
                        Your browser does not support PDFs. Please download the PDF: <a href={file}>Download PDF</a>
                      </p>
                    </object>
                  );
                } else {
                  return (
                    <img
                      key={index}
                      className="prescription-image"
                      src={file}
                      alt={`Prescription ${index + 1}`}
                    />
                  );
                }
              })}
            </div>
          </div>
        )}
        <div className='overlay-order-popup'></div>
        <div className="order-detail-popup">
          <div className="head">
            <h2>Order Test Details</h2>
            <a href="" className="close-popup" onClick={e => {
              e.preventDefault();
              closeEditTestsPanel();
            }}><span className="icon-clear"></span></a>
          </div>
          <div className="form-row autocomplete">
            <Autocomplete
              type="text"
              placeholder="Add medical test types"
              getItemValue={item => item.label}
              items={scanTypes}
              renderItem={(item, isHighlighted) => (
                scanTypes.length &&
                <div key={item.id} style={{background: isHighlighted ? "lightgray" : "white"}}>
                  {item.label}
                </div>
              )}
              value={test}
              onChange={e => {
                setTest(e.target.value);
                searchOrderScanTypes(e.target.value);
              }}
              onSelect={(val, e) => {
                if (!e.isAllowed) {
                  toast.error('This test isn\'t allowed in home sampling. Please ask the patient to visit the branch');
                  return;
                }
                setTests([
                  ...tests,
                  {
                    id: e.id,
                    name: e.name,
                    code: e.code,
                    value: +e.value,
                    index: tests.length,
                  }]);
                setTest('');
              }}
            />
          </div>
          {scanTypesLoading && <Loader/>}
          <ul className="test-list">
            {tests && tests.map(scanType => (
              <li key={`${scanType.id}${scanType.index}`}>
                <a href="" className="delete-test" id={scanType.id} onClick={e => {
                  e.preventDefault();
                  setTests(tests.filter(t => t.index !== scanType.index));
                  setTest('');
                }}>
                  <span className="icon-clear"></span>
                </a>
                <span className="text">{scanType.name} {scanType.code}</span></li>)
            )}
          </ul>
          <div className="form-row">
            <label>Discount</label>
            <div className="value">
              <span className="rs">%</span>
              <input
                type="text"
                name='discount'
                placeholder="Order Discount"
                value={discount}
                onChange={e => {
                  const d = +e.target.value;
                  if (d >= 0 && d <= 100) {
                    setDiscount(+e.target.value)
                  }
                }}
              />

            </div>
          </div>
          <div className="total-price">
            <span className="title">Total</span>
            <span className="des">{value.toFixed(0)}</span>
          </div>
          <button className="submit-btn" onClick={() => {
            orderScanTypes()
          }
          }>Save
          </button>
        </div>
      </div>
    </div>
    }
    {orderDetail.id &&
    <OrderAssignForm getAssignableRider={getAssignableRider}
                     assignableRider={assignableRider}
                     orderDetail={orderDetail}
                     regions={regions}
                     closeModal={closeModal}
                     assignOrder={mode === 'assign' ? assignOrder : reAssignOrder}
                     getCustomerDetail={getCustomerDetail}
                     customerDetail={orderDetail.customer}
                     mode={mode}
                     modalIsOpen={modalIsOpen}
                     assignableRiderLoading={assignableRiderLoading}
                     orderParams={bloodOrderParams}
                     reassignmentStrings={reassignmentStrings}
                     vertical={VERTICALS.blood}
                     getRiderTasks={getRiderTasks}
                     tasks={riderTasks}
                     resetRiderTasksState={resetRiderTasksState}
    />
    }
    {isOpenRiderTasksModal &&
    <Modal isOpen={isOpenRiderTasksModal} onRequestClose={closeRiderTasksModal}>
      <RiderTasksList
        tasks={riderTasks}
        getRiderTasks={getRiderTasks}
        rider={orderDetail.rider}
        isLoading={assignableRiderLoading}
        closeModal={closeRiderTasksModal}
        resetRiderTasksState={resetRiderTasksState}
        vertical={VERTICALS.blood}
      />
    </Modal>
    }
    {isOpenOrderActivityModal &&
    <Modal isOpen={isOpenOrderActivityModal} onRequestClose={closeOrderActivityModal}>
      <OrderActivity
        orderDetail={orderDetail}
        orderActivities={orderActivities}
        getOrderActivities={getOrderActivities}
        resetOrderActivitiesState={resetOrderActivitiesState}
        isLoading={ordersLoading}
        closeModal={closeOrderActivityModal}
      />
    </Modal>
    }
    {
      isPinActivityModalOpen &&
      <Modal isOpen={isPinActivityModalOpen} onRequestClose={closePinActivityModal}>
        <PinActivity
          address={orderDetail.customerAddress}
          closeModal={closePinActivityModal}
        />
      </Modal>
    }
    {isOpenCancelOrderModal &&
    <Modal isOpen={isOpenCancelOrderModal} onRequestClose={closeCancelOrderModal} className='small-modal'>
      <OrderCancel
        orderId={orderDetail.id}
        orderCancel={orderCancel}
        closeModal={closeCancelOrderModal}
        cancellationStrings={cancellationStrings}
      />
    </Modal>
    }
    {
      <Modal isOpen={isFollowUpModalOpen} onRequestClose={closeFollowUpOrderModal} className='small-modal'>
        <FollowUp
          orderId={orderDetail.id}
          followUpOrder={orderFollowUp}
          closeModal={closeFollowUpOrderModal}
        />
      </Modal>
    }

    {isOpenRescheduleOrderModal &&
    <Modal isOpen={isOpenRescheduleOrderModal} onRequestClose={closeRescheduleOrderModal}>
      <RescheduleOrder
        orderDetail={orderDetail}
        rescheduleOrder={rescheduleOrder}
        getOrderTimeSlots={getOrderTimeSlots}
        resetOrderTimeSlotsState={resetOrderTimeSlotsState}
        orderTimeSlots={orderTimeSlots}
        isLoading={ordersLoading}
        closeModal={closeRescheduleOrderModal}
        vertical={VERTICALS.blood}
        rescheduleStrings={rescheduleStrings}
      />
    </Modal>
    }
    {isOpenOrderReviewModal &&
    <Modal isOpen={isOpenOrderReviewModal} onRequestClose={closeOrderReviewModal}>
      <OrderReviewPopup
        orderReview={orderReview}
        closeModal={closeOrderReviewModal}
      />
    </Modal>
    }
    {isOpenRiderToPatientFeedbackModal &&
    <Modal isOpen={isOpenRiderToPatientFeedbackModal} onRequestClose={closeRiderToPatientFeedbackModal}>
      <RiderToPatientFeedbackPopup
        riderToPatientFeedback={riderToPatientFeedback}
        closeModal={closeRiderToPatientFeedbackModal}
      />
    </Modal>
    }
    {
      <Modal isOpen={isAdditionalNotesModalOpen} onRequestClose={closeAdditionalNotesModal} className='small-modal'>
        <AdditionalNotes
          orderId={orderDetail.id}
          additionalNotes={additionalNotes}
          orderAdditionalNotes={orderAdditionalNotes}
          closeModal={closeAdditionalNotesModal}
        />
      </Modal>
    }
    {isOpenDeleteCustomerAddressModal &&
    <Modal isOpen={isOpenDeleteCustomerAddressModal} onRequestClose={closeDeleteCustomerAddressModal}
           className='small-modal'>
      <DeleteCustomerAddress
        customerAddressId={orderDetail.customerAddress.id}
        handleCustomerAddressDeletion={handleCustomerAddressDeletion}
        closeModal={closeDeleteCustomerAddressModal}
      />
    </Modal>
    }
  </div>
};
export default BloodOrdersDetail;
